/* eslint-disable */
import React from 'react';
import { navigate, Link } from 'gatsby';
import styled from '@emotion/styled';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import {Box, BoxInline, BoxVisible, Flex} from '../../style/basicStyle';
import colors from '../../utils/colors';
import toast from "../../utils/toast";

const Container = styled.div`
  flex: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 315px;
  height: 380px;
  box-shadowDISABLED: inset 0 0 18px 1px #e3e7ed;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: small;
  padding: 10px;
  box-sizing: border-box;

  :hover {
    background: rgb(218, 216, 216);
  }
`;

const ImageContainer = styled.div`
  height: 237px;
  width: 237px;
`;

const shopImageCss = css`
  width: 100%;
  height: auto;
  border-radius: 50%;
`;

export const ProfileCardShop = ({ image, name, title, price, shopId, dataItemUrl, member, shopItemColors, customField1,customField2,customField3,customField4,customField5,customField6,customField7,customField8,customField9,customField10,checkbox1,checkbox2, slug, index, addToCart, registrationRequired, authUser, path, ...props }) => {
  const shopItemColorNum = shopItemColors && shopItemColors.length;
    const checkbox1Name = checkbox1.name;
    const checkbox2Name = checkbox2.name;
    const customField1Name = customField1.name;
    const customField2Name = customField2.name;
    const customField3Name = customField3.name;
    const customField4Name = customField4.name;
    const customField5Name = customField5.name;
    const customField6Name = customField6.name;
    const customField7Name = customField7.name;
    const customField8Name = customField8.name;
    const customField9Name = customField9.name;
    const customField10Name = customField10.name;

    const outOfStock = member.outOfStock;


  const customField1HyperlinkTitle = customField1.hyperlinktitle;
  const customField2HyperlinkTitle = customField2.hyperlinktitle;
  const customField3HyperlinkTitle = customField3.hyperlinktitle;
  const customField4HyperlinkTitle = customField4.hyperlinktitle;
  const customField5HyperlinkTitle = customField5.hyperlinktitle;
  const customField6HyperlinkTitle = customField6.hyperlinktitle;
  const customField7HyperlinkTitle = customField7.hyperlinktitle;
  const customField8HyperlinkTitle = customField8.hyperlinktitle;
  const customField9HyperlinkTitle = customField9.hyperlinktitle;
  const customField10HyperlinkTitle = customField10.hyperlinktitle;


  const customField1HyperlinkUrl = customField1.hyperlinkurl;
  const customField2HyperlinkUrl = customField2.hyperlinkurl;
  const customField3HyperlinkUrl = customField3.hyperlinkurl;
  const customField4HyperlinkUrl = customField4.hyperlinkurl;
  const customField5HyperlinkUrl = customField5.hyperlinkurl;
  const customField6HyperlinkUrl = customField6.hyperlinkurl;
  const customField7HyperlinkUrl = customField7.hyperlinkurl;
  const customField8HyperlinkUrl = customField8.hyperlinkurl;
  const customField9HyperlinkUrl = customField9.hyperlinkurl;
  const customField10HyperlinkUrl = customField10.hyperlinkurl;


  const checkbox1Value = checkbox1.value;
    const checkbox2Value = checkbox2.value;
    const customField1Placeholder = customField1.placeholder;
    const customField2Placeholder = customField2.placeholder;
    const customField3Placeholder = customField3.placeholder;
    const customField4Placeholder = customField4.placeholder;
    const customField5Placeholder = customField5.placeholder;
    const customField6Placeholder = customField6.placeholder;
    const customField7Placeholder = customField7.placeholder;
    const customField8Placeholder = customField8.placeholder;
    const customField9Placeholder = customField9.placeholder;
    const customField10Placeholder = customField10.placeholder;
    const customField1Values = customField1.values;
    const customField2Values = customField2.values;
    const customField3Values = customField3.values;
    const customField4Values = customField4.values;
    const customField5Values = customField5.values;
    const customField6Values = customField6.values;
    const customField7Values = customField7.values;
    const customField8Values = customField8.values;
    const customField9Values = customField9.values;
    const customField10Values = customField10.values;
    const customField1Required = customField1.required;
    const customField2Required = customField2.required;
    const customField3Required = customField3.required;
    const customField4Required = customField4.required;
    const customField5Required = customField5.required;
    const customField6Required = customField6.required;
    const customField7Required = customField7.required;
    const customField8Required = customField8.required;
    const customField9Required = customField9.required;
    const customField10Required = customField10.required;
    const customField1Default = customField1.default;
    const customField2Default = customField2.default;
    const customField3Default = customField3.default;
    const customField4Default = customField4.default;
    const customField5Default = customField5.default;
    const customField6Default = customField6.default;
    const customField7Default = customField7.default;
    const customField8Default = customField8.default;
    const customField9Default = customField9.default;
    const customField10Default = customField10.default;
    const checkbox1Selection = false;
    const checkbox2Selection = false;
    const checkbox3Selection = false;
    const checkbox4Selection = false;
    const checkbox5Selection = false;
    const checkbox6Selection = false;
    const checkbox7Selection = false;
    const checkbox8Selection = false;
    const checkbox9Selection = false;
    const checkbox10Selection = false;
    const customField1Selection = customField1Default;
    const customField2Selection = customField2Default;
    const customField3Selection = customField3Default;
    const customField4Selection = customField4Default;
    const customField5Selection = customField5Default;
    const customField6Selection = customField6Default;
    const customField7Selection = customField7Default;
    const customField8Selection = customField8Default;
    const customField9Selection = customField9Default;
    const customField10Selection = customField10Default;
    const itemColors =
        shopItemColors &&
        shopItemColors[0] &&
        shopItemColors[0].name &&
        shopItemColors[0].name.length &&
        shopItemColors
            .filter(itemColor => itemColor.name && itemColor.name.length > 0)
            .map(itemColor => {
                itemColor.name = itemColor.name.trim().toLowerCase();
                itemColor.src = itemColor.src.trim().toLowerCase();
                return itemColor;
            });

  function processClick(e, linkTo) {
    // console.log('ProfileCardShop: processClick - event ', e,' || processClick: ', linkTo);
    if (!registrationRequired || authUser) {
      if (linkTo) {
        navigate(linkTo);
      } else {
        addToCart(e)
      }
    } else {
      e.preventDefault();
      e.stopPropagation();
      toast('You must register to buy this item - click here.', {
        onClick: function clicked() {
          navigate('/signup', { state: {redirect: path} });
        },
        closeButton: false,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        autoClose: 10000,
        closeOnClick: false,
        disableAutoClose: true
      });
    }
  }
  const itemProps = {};
  itemProps.type="button";
  itemProps.onClick= function onClick(e) {
    processClick(e);
  };
  itemProps.style={ fontWeight: 'bold', borderColor: colors.tertiary, borderStyle: 'solid', borderWidth: '0.5px', padding: '5px', backgroundColor: colors.tertiary, cursor: 'pointer' };
  itemProps.id="buyButton";
  if (!registrationRequired || authUser) {
    // ensure we do not render this class name for the button if the item requires registration...
    itemProps.className="snipcart-add-item";
  }
  if (outOfStock) {
    itemProps.disabled = true;
    delete itemProps.id;
    delete itemProps.className;
  }
  itemProps['data-item-id']=shopId;
  itemProps['data-item-price']=price;
  itemProps['data-item-image']=(image && image.childImageSharp && image.childImageSharp.gatsbyImageDataFixed && image.childImageSharp.gatsbyImageDataFixed.images && image.childImageSharp.gatsbyImageDataFixed.images.fallback && image.childImageSharp.gatsbyImageDataFixed.images.fallback.src) || '../../../bulldogshockeyassets/images/shopPlaceholder.jpg';
  itemProps['data-item-name']= name;
  itemProps['data-item-description']=title;
  itemProps['data-item-url']=dataItemUrl;
  if (itemColors) itemProps['data-item-custom1-name']=itemColors && 'Color';
  if (itemColors) itemProps['data-item-custom1-options']=
    itemColors &&
    itemColors
      .map((colorItem) => colorItem.title)
      .toString()
      .replace(/,/g, '|')
  ;
  if (itemColors && itemColors[0] && itemColors[0].value && itemColors[0].value.length) itemProps['data-item-custom1-value']= itemColors[0].value.length;
  if (itemColors && itemColors.length) itemProps['data-item-custom1-required']= true;
  if (customField1Name && customField1Name.length) itemProps['data-item-custom2-name']= customField1Name;
  // eslint-disable-next-line prefer-destructuring
  if (customField1Name && customField1Name.length && customField1Selection && customField1Selection.length) itemProps['data-item-custom2-value']=customField1Selection.split('[')[0];
  if (customField1Name && customField1Name.length && customField1Values && customField1Values.length) itemProps['data-item-custom2-options']= customField1Values.toString().replace(/,/g, '|');
  if (customField1Name && customField1Name.length && customField1Required && customField1Required !== 'false') itemProps['data-item-custom2-required']= true;
  if (customField2Name && customField2Name.length) itemProps['data-item-custom3-name']= customField2Name;
  if (customField2Name && customField2Name.length && customField2Values && customField2Values.length) itemProps['data-item-custom3-options']= customField2Values.toString().replace(/,/g, '|');
  // eslint-disable-next-line prefer-destructuring
  if (customField2Name && customField2Name.length && customField2Selection && customField2Selection.length ) itemProps['data-item-custom3-value']= customField2Selection.split('[')[0];
  if (customField2Name && customField2Name.length && customField2Required && customField2Required !== 'false') itemProps['data-item-custom3-required']= true;


  if (customField3Name && customField3Name.length) itemProps['data-item-custom4-name']= customField3Name;
  if (customField3Name && customField3Name.length && customField3Values && customField3Values.length) itemProps['data-item-custom4-options']= customField3Values.toString().replace(/,/g, '|');
  // eslint-disable-next-line prefer-destructuring
  if (customField3Name && customField3Name.length && customField3Selection && customField3Selection.length ) itemProps['data-item-custom4-value']= customField3Selection.split('[')[0];
  if (customField3Name && customField3Name.length && customField3Required && customField3Required !== 'false') itemProps['data-item-custom4-required']= true;

  if (customField4Name && customField4Name.length) itemProps['data-item-custom5-name']= customField4Name;
  if (customField4Name && customField4Name.length && customField4Values && customField4Values.length) itemProps['data-item-custom5-options']= customField4Values.toString().replace(/,/g, '|');
  // eslint-disable-next-line prefer-destructuring
  if (customField4Name && customField4Name.length && customField4Selection && customField4Selection.length ) itemProps['data-item-custom5-value']= customField4Selection.split('[')[0];
  if (customField4Name && customField4Name.length && customField4Required && customField4Required !== 'false') itemProps['data-item-custom5-required']= true;


  if (customField5Name && customField5Name.length) itemProps['data-item-custom6-name']= customField5Name;
  if (customField5Name && customField5Name.length && customField5Values && customField5Values.length) itemProps['data-item-custom6-options']= customField5Values.toString().replace(/,/g, '|');
  // eslint-disable-next-line prefer-destructuring
  if (customField5Name && customField5Name.length && customField5Selection && customField5Selection.length ) itemProps['data-item-custom6-value']= customField5Selection.split('[')[0];
  if (customField5Name && customField5Name.length && customField5Required && customField5Required !== 'false') itemProps['data-item-custom6-required']= true;


  if (customField6Name && customField6Name.length) itemProps['data-item-custom7-name']= customField6Name;
  if (customField6Name && customField6Name.length && customField6Values && customField6Values.length) itemProps['data-item-custom7-options']= customField6Values.toString().replace(/,/g, '|');
  // eslint-disable-next-line prefer-destructuring
  if (customField6Name && customField6Name.length && customField6Selection && customField6Selection.length ) itemProps['data-item-custom7-value']= customField6Selection.split('[')[0];
  if (customField6Name && customField6Name.length && customField6Required && customField6Required !== 'false') itemProps['data-item-custom7-required']= true;

  if (customField7Name && customField7Name.length) itemProps['data-item-custom8-name']= customField7Name;
  if (customField7Name && customField7Name.length && customField7Values && customField7Values.length) itemProps['data-item-custom8-options']= customField7Values.toString().replace(/,/g, '|');
  // eslint-disable-next-line prefer-destructuring
  if (customField7Name && customField7Name.length && customField7Selection && customField7Selection.length ) itemProps['data-item-custom8-value']= customField7Selection.split('[')[0];
  if (customField7Name && customField7Name.length && customField7Required && customField7Required !== 'false') itemProps['data-item-custom8-required']= true;

  if (customField8Name && customField8Name.length) itemProps['data-item-custom9-name']= customField8Name;
  if (customField8Name && customField8Name.length && customField8Values && customField8Values.length) itemProps['data-item-custom9-options']= customField8Values.toString().replace(/,/g, '|');
  // eslint-disable-next-line prefer-destructuring
  if (customField8Name && customField8Name.length && customField8Selection && customField8Selection.length ) itemProps['data-item-custom9-value']= customField8Selection.split('[')[0];
  if (customField8Name && customField8Name.length && customField8Required && customField8Required !== 'false') itemProps['data-item-custom9-required']= true;

  if (customField9Name && customField9Name.length) itemProps['data-item-custom10-name']= customField9Name;
  if (customField9Name && customField9Name.length && customField9Values && customField9Values.length) itemProps['data-item-custom10-options']= customField9Values.toString().replace(/,/g, '|');
  // eslint-disable-next-line prefer-destructuring
  if (customField9Name && customField9Name.length && customField9Selection && customField9Selection.length ) itemProps['data-item-custom10-value']= customField9Selection.split('[')[0];
  if (customField9Name && customField9Name.length && customField9Required && customField9Required !== 'false') itemProps['data-item-custom10-required']= true;


  if (customField10Name && customField10Name.length) itemProps['data-item-custom11-name']= customField10Name;
  if (customField10Name && customField10Name.length && customField10Values && customField10Values.length) itemProps['data-item-custom11-options']= customField10Values.toString().replace(/,/g, '|');
  // eslint-disable-next-line prefer-destructuring
  if (customField10Name && customField10Name.length && customField10Selection && customField10Selection.length ) itemProps['data-item-custom11-value']= customField10Selection.split('[')[0];
  if (customField10Name && customField10Name.length && customField10Required && customField10Required !== 'false') itemProps['data-item-custom11-required']= true;

  if (checkbox1Name && checkbox1Name.length) itemProps['data-item-custom12-name']= checkbox1Name;
  if (checkbox1Name && checkbox1Name.length) itemProps['data-item-custom12-type']="checkbox";
  if (checkbox1Name && checkbox1Name.length && checkbox1Selection) itemProps['data-item-custom12-value']= checkbox1Selection;
  if (checkbox2Name && checkbox2Name.length) itemProps['data-item-custom13-name']= checkbox2Name;
  if (checkbox2Name && checkbox2Name.length) itemProps['data-item-custom13-type']="checkbox"
  if (checkbox2Name && checkbox2Name.length && checkbox2Selection) itemProps['data-item-custom13-value']=checkbox2Selection;
    return (
    <Link onClick={(e) => processClick(e, slug)} onKeyPress={(e) => processClick(e, slug)} to={slug}>
      <Container>
        <ImageContainer>{image && image.childImageSharp && image.childImageSharp.gatsbyImageDataFixed ? <GatsbyImage alt="shopImage" image={image && image.childImageSharp && image.childImageSharp.gatsbyImageDataFixed} /> : <StaticImage css={shopImageCss} alt="image placeholder for shop" src='../../../bulldogshockeyassets/images/shopPlaceholder.jpg' />}</ImageContainer>
        <Box fontSize={[3, 4, 4]}>{`${name}`}</Box>
        <Box fontSize={[2, 2, 2, 2]} m={[1]}>{`${title}`}</Box>
        <Box color="green" m={[0]} fontSize={[3, 3, 3, 3]}>${`${price}`}<BoxInline color="black">{(shopItemColorNum && shopItemColorNum > 1) && ` - ${shopItemColorNum} colors`}</BoxInline></Box>
        {/*
        disabling for now until I can fix click propagation issues. I used to have e.preventdefault but that stopped working and now it blocks snipcart from working at all.
        <Box fontSize={[3, 3, 4, 4]} >
            {!outOfStock ? (
            <button onClick={(e) => processClick(e)} onKeyPress={(e) => processClick(e)} {...itemProps}>
              Add to Cart
            </button>) : (<button type="button" {...itemProps}>
            Out Of Stock
          </button>)}
          </Box>
           */}
      </Container>
    </Link>
  );
};
